import { ServiceContainer } from '@wsi/web-core/utils/ServiceContainer'

const ServiceContainerSingleton = () => {
  let serviceContainer = null
  return {
    getInstance: function (cfg) {
      if (serviceContainer == null) {
        serviceContainer = new ServiceContainer(cfg)
      }
      return serviceContainer
    }
  }
}

const serviceContainerSingleton = ServiceContainerSingleton()

export function useServiceContainer () {
  return serviceContainerSingleton.getInstance()
}

export function useServiceContainerServices () {
  return useServiceContainer().getServices()
}

export function useServiceContainerConfig () {
  return useServiceContainer().getContainerConfig()
}

export function setServiceContainerConfig (cfg) {
  return useServiceContainer().setContainerConfig(cfg)
}
