export const FLAG_UAT_MOCKED_DOCS = true
export const FLAG_UAT_ADAPTER = true
export const FLAG_UAT_CUSTOMER_APP_LINKS = true
export const INFORMATIVA_LINK = 'https://d1iouzi55x1cvc.cloudfront.net/attachments/Informativa+privacy+wefox+MGA.pdf'
export const IMPRESSUM_LINK = 'https://www.wefox.com/it-it/impressum'
export const TERMS_AND_CONDITIONS = 'https://www.wefox.com/it-it/termini-condizioni'
export const COOKIES_LINK = '#'
export const USE_HERO_NAVBAR = 'HERO_NAVBAR'
export const USE_LEVEL_NAVBAR = 'LEVEL_NAVBAR'

export const ROUTE_LOGIN = 'Login'
export const ROUTE_LOGOUT = 'Logout'
export const ROUTE_CONTACT_US = 'ContactUs'
export const ROUTE_PRV_START = 'Start'
export const ROUTE_HOME = ROUTE_PRV_START
export const ROUTE_PRV_READY = 'PreventivatoreReady'
export const ROUTE_PRV_END = 'PreventivatoreEnd'
export const ROUTE_PRV_MICROAPP = 'PreventivatoreMicroapp'
export const ROUTE_PRV_MICROAPP_FLOW = 'PreventivatoreMicroappFlow'
export const ROUTE_PRV_UPLOAD_DOCUMENTS = 'PreventivatoreUploadDocuments'
export const ROUTE_PRV_SPECIAL_PERSINFO = 'PreventivatoreSpecialPersonalInfo'
export const ROUTE_PYM_TERMS = 'Payment'
export const ROUTE_PYM_RECEIPT = 'PagamentoReceipt'
export const ROUTE_AREA_PRV = 'AreaPersonalePreventivi'
export const ROUTE_AREA_SIN = 'AreaPersonaleSinistri'
export const ROUTE_AREA_POL = 'AreaPersonalePolizze'
export const ROUTE_AREA_STO = 'AreaPersonaleStorico'
export const ROUTE_AREA_DATA = 'AreaPersonaleData'
export const ROUTE_AREA_PRV_DT = 'AreaPersonalePreventiviDettaglio'
export const ROUTE_AREA_RENEWALS = 'AreaPersonaleRinnovi'
export const ROUTE_AREA_POL_DT = 'AreaPersonalePolizzaDettaglio'
export const ROUTE_DISPATCH = 'Dispatch'
export const ROUTE_404 = '404'
export const ROUTE_CONFIRM_USER_DATA = 'ConfirmUserData'
export const ROUTE_MAINTENANCE_PAGE = 'Maintenance'

export const APP_NAME = 'RCA_B2C'
export const LOCALE_IT = 'it'
export const LOCALE_EN = 'en'
export const CAR = 'car'
export const MOTORCYCLE = 'motorcycle'
export const MOPED = 'moped'
export const LIGHT_TRUCK = 'light_truck'
export const TRUCK = 'truck'
export const TRUCK_CT = 'truck-ct'
export const CAMPER = 'camper'
export const TRAVEL = 'aeroplane'
export const BOATSHIP = 'boat'
export const HELICOPTER = 'helicopter'
export const SHUTTLE = 'shuttle'
export const VAN = 'van'
export const BUS = 'bus'
export const FARM_MACHINE = 'agriculture_car'
export const OPERATING_MACHINE = 'work_car'

export const SKW = 'SKW'

// SHARED WITH BE2FE payment type
export const PAYMENT_CREDIT_CARD = 'CREDIT_CARD'
export const PAYMENT_BANK = 'BANK'

export const QUOTATION_PRC = 'PRC'
export const QUOTATION_PRI = 'PRI'
export const QUOTATION_RENEWAL = 'renewal'
export const QUOTATION_UNRESTRICTED = 'UNRESTRICTED'
export const QUOTATION_REPLACEMENT = 'REPLACEMENT'
export const QUOTATION_RENEWALS = 'RENEWALS'

export const BUSINESS_TYPE_RENEWAL = 'renewal'
export const BUSINESS_TYPE_FALSE_NEW = 'false_new_business'
export const BUSINESS_TYPE_NEW = 'newBusiness'

export const BO_VISITOR_ID = 'bo-visitor_id'

export const QUOTATION_STATE_PREPARED = 'prepared'
export const QUOTATION_STATE_PAID = 'paid'

export const MIN_PASSWORD_LEN = 12
export const SMARTPHONE = 'SMARTPHONE'
export const TABLET = 'TABLET'
export const DESKTOP = 'DESKTOP'

export const POL_STATUS_SUSPENDED = 'SUSPENDED'
export const POL_STATUS_ACTIVE = 'ACTIVE'
export const POL_STATUS_EXPIRED = 'EXPIRED'

export const APP_LANGUAGES = [
  { id: LOCALE_IT },
  { id: LOCALE_EN }
]

export const OPEN_CARD_DETAIL = 'detail'
export const OPEN_CARD_UPLOAD_DOCS = 'upload-docs'
export const OPEN_CARD_PROCEED_PAY = 'proceed_pay'

export const PAYMENT_IBAN = 'IT8277770026997528'
export const PAYMENT_BENEFICIARY = 'weFox Spa'

export const DOCS_UPLOADED_NOW = 'DOCS_UPLOADED_NOW'
export const DOCS_TO_BE_UPLOADED = 'DOCS_TO_BE_UPLOADED'

export const TOKEN_TYPE_ANON = 'PHANTOM'
export const TOKEN_TYPE_USER = 'USER'

export const PRIVACY_FILE = 'https://d1iouzi55x1cvc.cloudfront.net/attachments/Informativa+Privacy+RCA_IT_rev3.2.pdf'

export const SOURCE_ID_SEGUGIO = 'segugio'
export const SOURCE_ID_BO_WEFOX = 'bo-wefox'

export const ORIGIN_BO = 'bo'

export const FLOW_MODE_BO = 'FLOW_MODE_BO'
export const FLOW_MODE_STANDARD = 'FLOW_MODE_STANDARD'

export const DISPATCH_QUOTE = 'quote'
export const DISPATCH_REQUOTE = 'requote'
export const DISPATCH_CLONE = 'clone'
export const DISPATCH_FULLQUOTE = 'fullquote'

export const CONTO_PROPRIO = 'CP'
export const CONTO_TERZI = 'CT'

export const DEFAULT_CONTRACTOR_MIN_AGE = 18

export const FLOW_PHYSICAL = 'PHYSICAL'
export const FLOW_LEGAL = 'LEGAL'

export const VEHICLE_INFO_STEP_1 = 'VEHICLE_INFO_STEP_1'
export const VEHICLE_INFO_STEP_2 = 'VEHICLE_INFO_STEP_2'
export const VEHICLE_INFO_STEP_3 = 'VEHICLE_INFO_STEP_3'
export const INSURANCE_INFO_STEP_1 = 'INSURANCE_INFO_STEP_1'
export const INSURANCE_INFO_STEP_2 = 'INSURANCE_INFO_STEP_2'
export const INSURANCE_INFO_STEP_3 = 'INSURANCE_INFO_STEP_3'
export const INSURANCE_INFO_STEP_4 = 'INSURANCE_INFO_STEP_4'

export const QUOTE_LEVEL_1 = 1
export const QUOTE_LEVEL_2 = 2
export const QUOTE_LEVEL_3 = 3
