import { useServiceContainer } from './services/ServiceContainerSingleton'
import FS from '@wsi/web-core/services/FederationService'
import TrackingService from '@rca/vue-core/shell/services/TrackingService'
import _LogService from '@rca/vue-core/shell/services/LogService'

import { APP_NAME } from './constants/index'

const serviceContainer = useServiceContainer()

const LogService = _LogService()
LogService.addWindowListeners()

let cfg = null
try {
  cfg = await (await fetch('/cfg')).json()
} catch (error) {
  console.log(error) // eslint-disable-line
}

serviceContainer.setContainerConfig(cfg)
serviceContainer.registerServices({
  FederationService: new FS(cfg),
  TrackingService: new TrackingService(
    { apiUrl: cfg.API_URL, appName: APP_NAME, ENABLE_TRACKING: cfg.ENABLE_TRACKING }
  ),
  LogService
})

import('./bootstrap')
